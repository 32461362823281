/**
 * 和「专家评审」相关的 api 函数
 */
import request from '@/utils/request'

export function getOwnJudgeList() {
  return request({
    url: '/judge/own-list',
    method: 'GET'
  })
}

export function getOwnJudge(
  pageNum,
  pageSize,
  judgeId,
  judgeStatus = null,
  scoreRangeMinInclusive = 0,
  scoreRangeMaxExclusive = 101) {
  return request({
    url: `/judge/${judgeId}/own-judge-work-list`,
    method: 'GET',
    params: { judgeStatus, scoreRangeMinInclusive, scoreRangeMaxExclusive, pageNum, pageSize }
  })
}

export function getExpertJudgeStatistics(judgeId) {
  return request({
    url: `/judge/${judgeId}/statistics`,
    method: 'GET'
  })
}
