import request from '@/utils/request'

export function getJudgeInfo(judgeId) {
  return request({
    url: `/judge/${judgeId}/judgeVO`,
    method: 'GET'
  })
}

export function judgeWork(judgeScoreId, score = -1, TBD = false) {
  const query = {
    score: score,
    TBD: TBD
  }
  return request({
    url: `/judgeScore/${judgeScoreId}`,
    method: 'PUT',
    params: query
  })
}
