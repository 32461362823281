<template>
  <div>
    <div class="container">
      <div class="notice-box swiper-box">
        <!-- 头部条 -->
        <div class="header">
          <!-- 返回按钮 -->
          <div class="back">
            <el-button type="text" icon="el-icon-d-arrow-left" @click="goBack">返回</el-button>
          </div>
          <!-- 缩略图与列表切换按钮 -->
          <div class="change-show-status" @click="showRules">
            <div class="status-icon">
              <svg-icon name="file" width="18" />
            </div>
            <span class="status-text">评审规则</span>
          </div>
          <!-- 评审名称 -->
          <div class="hd-name">
            {{ judgeName }}
          </div>
          <!-- 筛选框 -->
          <!-- <div class="hd-select">
            <el-select v-model="status_selected" class="select-box" size="small" placeholder="请选择" @change="getWorkList">
              <el-option
                v-for="item in statuslist"
                :key="item"
                :value="item"
                :lable="item"
              />
            </el-select>
          </div> -->
          <!-- 数量统计显示 -->
          <div class="hd-statistic">
            <span class="hd-work-status">
              当前作品状态：{{ curWork.judgeStatus }}
            </span>
            <div v-for="propName in statusShowlist" :key="propName" class="hd-stta-box">
              <span>{{ propName }}: </span>
              <span :class="[propName !== '未评审' ? 'theme-color' : 'red']">{{ statisticRecord[propName] }}</span>
            </div>
            <div class="hd-stta-box">
              <span>作品总数: </span>
              <span>{{ workTotal }}</span>
            </div>
          </div>
        </div>

        <!-- 评审部分 -->
        <div class="swiper-box">
          <work-swiper :cur-work.sync="curWork" :judge-status.sync="condition.curJudgeStatus" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import '@/icons/file'
import '@/icons/goleft'
import '@/icons/goright'
import 'video.js/dist/video-js.css'
import { getExpertJudgeStatistics } from '@/api/expert'
import { getJudgeInfo } from '@/api/judge'
import WorkSwiper from './components/workSwiper'

export default {
  components: { WorkSwiper },
  data() {
    return {
      curIndex: 0,
      maxPage: 0,
      condition: {},
      curJudgeInfo: {},
      statusShowlist: ['已评审', '待定', '未评审'],
      statisticRecord: {
        '未评审': 0,
        '正在评审': 0,
        '已评审': 0,
        '待定': 0,
        total: 0
      },

      statuslist: ['全部', '未评审', '待定', '已评审'],
      status_selected: '未评审',
      statisticlist: [{
        status: 2,
        name: '已评审', // 2
        number: '20'
      }, {
        status: 3,
        name: '待定', // 3
        number: '200'
      }, {
        status: 0,
        name: '未评审', // 0
        number: '10'
      }],
      isList: true, // 判断是列表还是缩略图显示

      // 目前正在评审的作品
      curWork: {}

    }
  },
  computed: {
    judgeName() {
      return this.curJudgeInfo.judgeName
    },
    regulationSrc() {
      return this.PR.previewOrigin(this.curJudgeInfo.regulationSrc)
    },
    workTotal() {
      return this.statisticRecord.total
    }
  },
  watch: {
    curWork(nv) {
      this.init()
    }
  },
  created() {
    this.condition = this.$store.state.judge.judgework_info
    // console.log(this.condition)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // this.curIndex = this.condition.curIndex + 1

      this.getCurrentJudgeDetail()
      this.getExpertJudgeStatistics()
      // this.getWorkList(this.condition.pageNum, this.condition.pageSize)
    },
    // 查看评审规则
    showRules() {
      window.open(this.regulationSrc)
    },
    getCurrentJudgeDetail() {
      getJudgeInfo(this.condition.curJudgeId).then(res => {
        this.curJudgeInfo = res.data
      })
    },
    getExpertJudgeStatistics() {
      getExpertJudgeStatistics(this.condition.curJudgeId).then(res => {
        this.statisticRecord = res.data
      })
    },
    // 返回到上一页
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1050px;
  /* border: 1px solid red; */
}

.header {
  height: 60px;
  background-color: #c8c8c9;
  border-radius: 10px 10px 0 0;
  text-align: left;
  font-size: 14px;
  /* line-height: 60px; */
}

.back {
  display: inline-block;
  line-height: 60px;
  margin-left: 20px;
}

.change-show-status {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  line-height: 60px;
  margin-left: 10px;
  font-weight: 800;
}

.change-show-status:hover {
  cursor: pointer;
}

.status-icon {
  position: absolute;
  display: inline-block;
  top: 4px;
  /* padding-top: 10px; */
}

.status-text {
  display: inline-block;
  margin-left: 20px;
  width: 60px;
}

/* 若超出，以...显示 */
.hd-name {
  display: inline-block;
  margin-left: 5px;
  /* width: 290px; */
  padding: 2px 5px;
  border-left: 2px solid rgb(155, 155, 155);
  /* border-right: 2px solid rgb(155, 155, 155); */
  margin-bottom: 19px;
  vertical-align: bottom;
}

.hd-select {
  display: inline-block;
}

.el-select {
  width: 100px;
  height: 30px;
  margin-left: 5px;
  /* font-size: 14px; */
}

.select-box /deep/ .el-input {
  font-size: 10px;
}

.hd-statistic {
  position: absolute;
  line-height: 60px;
  display: inline-block;
  right: 20px;
}

.hd-work-status {
  border-right: 2px solid rgb(155, 155, 155);
  border-left: 2px solid rgb(155, 155, 155);
  /* width: 70px; */
  padding: 2px 5px;
  height: 50px;
  /* font-weight: 800; */
  /* margin-right: 8px; */
}

.hd-stta-box {
  display: inline-block;
  margin-left: 8px;
  font-weight: 800;
}

.red {
  color: red;
}

.theme-color {
  color: var(--theme-color);
}

.fix-btn {
  position: absolute;
  line-height: 500px;
}

.left {
  left: 80px;
}

.right {
  right: 80px;
}

svg:hover {
  cursor: pointer;
}

.work-display {
  position: relative;
  width: 750px;
  height: 430px;
  margin: auto;
  margin-top: 20px;
}

.poster {
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 50%;
  left: 0px;
}

.v-and-m {
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 49%;
  right: 0px;
}

.model {
  /* display: inline-block; */
  position: absolute;
  width: 100%;
  height: 49%;
  top: 0px;
}

.video {
  height: 49%;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.null {
  background-color: rgb(206, 205, 205);
  line-height: 200px;
  text-align: center;
  color: rgb(85, 85, 85);
}

.border {
  border: 1px solid red;
}

.score {
  margin-top: 30px;
  /* height: 30px; */
  text-align: center;
}

.btn {
  display: inline-block;
  width: 80px;
}

.pending.el-button--primary {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.score-input {
  display: inline-block;
  width: 130px;
  margin-left: 30px;
  margin-right: 10px;
}
</style>
