<template>
  <div v-loading="loading">
    <div @click.stop>
      <el-image-viewer
        v-if="isShowImageViewer"
        :on-close="imgClose"
        :url-list="[worklist[curIndex].posterGenSrcOrigin]"
      />
    </div>
    <!-- 向左箭头 -->
    <div id="swiper-button-prev" class="fix-btn left swiper-button-prev swiper-button-black" @click="goPrev" />
    <!-- 向右箭头 -->
    <div id="swiper-button-next" class="fix-btn right swiper-button-next swiper-button-black" @click="goNext" />
    <!-- 作品展示部分 -->
    <swiper ref="swiper" :options="swiperOptions">
      <swiper-slide v-for="(item, idx) in worklist" :key="idx">
        <div v-if="curIndex === idx">
          <div class="work-display">
            <div class="poster">
              <img
                style="height:100%"
                :src="item.posterGenSrc"
                :preview-src-list="[item.posterGenSrc]"
                :alt="item.boardId"
                :title="item.specification"
                @click.stop="imgClick"
                @load="onPicLoad"
              >
            </div>
            <div class="v-and-m">
              <div v-if="item.modelSrc!==''" ref="model" class="model">
                <!--                      .dae文件-->
                <model-collada v-if="getModelSuffix()==='DAE'" :src="item.modelSrc" @on-load="onModelLoad" />
                <!--                      .fbx文件-->
                <model-fbx v-if="getModelSuffix()==='FBX'" :src="item.modelSrc" @on-load="onModelLoad" />
                <!--                      obj-->
                <model-obj v-if="getModelSuffix()==='OBJ'" :src="item.modelSrc" @on-load="onModelLoad" />
              </div>
              <div v-else class="model null">
                参赛者没有上传模型文件
              </div>
              <div v-if="item.videoSrc!==''" ref="video" class="video">
                <video-player
                  ref="videoPlayer"
                  class="video-player vjs-custom-skin"
                  :playsinline="true"
                  :options="playerOptions"
                  @loadeddata="onPlayerLoadeddata()"
                />
              </div>
              <div v-else class="video null">
                参赛者没有上传视频文件
              </div>
            </div>
          </div>
          <!-- 分数输入框 -->
          <div class="score">
            <el-button class="btn pending" type="primary" :disabled="isDisabled" @click="toSetScore(item.id, item.workName)">待定
            </el-button>
            <el-input v-model="item.score" class="score-input" :disabled="isDisabled" placeholder="请输入作品得分" @change="verifyScore(item)" />
            <el-button class="btn" type="primary" :disabled="isDisabled" @click="toSubmit(item.id, item.score, item.workName)">提交
            </el-button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { ModelObj, ModelCollada, ModelFbx } from 'vue-3d-model'
import { judgeWork } from '@/api/judge'
import { getOwnJudge } from '@/api/expert'

export default {
  name: 'WorkSwiper',
  components: { videoPlayer, Swiper, SwiperSlide, ElImageViewer, ModelObj, ModelCollada, ModelFbx },
  props: {
    curWork: {
      type: Object,
      default() {
        return {}
      }
    },
    judgeStatus: {
      type: String,
      default: '未完成'
    }
  },
  data() {
    return {
      loading: false,
      loadCount: 3,
      curIndex: 0,
      condition: {}, // 跳转分页条件
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: 1,
        direction: 'horizontal',
        allowTouchMove: false,
        navigation: {
          nextEl: '#swiper-button-next',
          prevEl: '#swiper-button-prev'
        },
        lazy: true,
        speed: 550
      },
      isShowImageViewer: false, // 是否展示大图
      posterSrc: 'http://www.bjuidc.ncut.edu.cn/api/file/preview-tiny/pictures/contest_24/4230697399.jpg',
      // 作品信息
      worklist: [{
        applicationSrc: '',
        boardId: '01A88802',
        catagoryId: 21,
        contestId: 2,
        createTime: '2021-04-18 05:44:46',
        id: 1000,
        isPublicized: true,
        modelSrc: '',
        posterGenSrc: '',
        posterSrc: 'http://www.bjuidc.ncut.edu.cn/api/file/preview-tiny/pictures/contest_24/4230697399.jpg',
        previousWorkId: 0,
        prizeName: '',
        specification: 'asd',
        studentId: 100004,
        updateTime: '2021-04-28 18:19:57',
        videoSrc: '',
        workName: 'a',
        // 后续会变成 true false，更新时注意改变
        workStatus: 9
      }]
    }
  },
  computed: {
    playerOptions() {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4', // 类型
          // src: '/Users/marianna/desktop/test.mp4' // url地址
          // !!!!! 修改此处
          src: this.worklist[this.curIndex].videoSrc
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    },
    isDisabled() {
      console.log('disabled judge status', this.judgeStatus)
      return this.judgeStatus !== '进行中'
    }
  },
  watch: {
    loadCount(nv) {
      if (nv === 0) {
        this.loading = false
      }
    },
    curIndex: {
      handler(nv) {
        console.log('cccur', nv)
        this.$emit('update:curWork', this.worklist[this.curIndex])
        setTimeout(() => {
          this.judgeComponentCount()
        }, 50)
      }
    }
  },
  created() {
    this.condition = this.$store.state.judge.judgework_info
    this.curIndex = this.condition.curIndex + 1
    this.$emit('update:curWork', this.worklist[this.curIndex])
  },
  mounted() {
    this.curIndex = this.condition.curIndex + 1
    this.getWorkList(this.condition.pageNum, this.condition.pageSize)
    window._this = this
  },
  methods: {
    verifyScore(item) {
      if (item.score < 0) {
        item.score = 0
        this.$message.warning('分数输入非法')
      }
      if (item.score > 100) {
        item.score = 100
        this.$message.warning('分数输入非法')
      }
      if (item.score % 1 !== 0) {
        item.score = Math.round(item.score)
        this.$message.warning('分数必须为整数，已自动为您四舍五入')
      }
    },
    // 点击图片显示大图
    imgClick() {
      console.log('click img')
      this.isShowImageViewer = true
      this.isLimited = true
    },
    imgClose() {
      window.event ? window.event.cancelBubble = true : event.stopPropagation()
      this.isLimited = true
      this.isShowImageViewer = false
    },
    // 根据所选状态与所选分数显示对应的作品列表
    getWorkList(pageNum, pageSize, turnpage) {
      this.loading = true
      const condition = this.condition
      getOwnJudge(pageNum, pageSize, condition.judgeId,
        condition.judgeStatus, condition.scoreRangeMinInclusive, condition.scoreRangeMaxExclusive).then(res => {
        if (res.data.pageNum !== pageNum) {
          // 请求的页码超过实际总页数或小于1
          this.$message.info('没有更多了！')
          return
        }

        this.maxPage = res.data.pages
        this.worklist = res.data.items
        // 判断 worklist 是否为空，为空的话返回上一层
        if (this.worklist.length === 0) {
          this.$router.go(-1)
        }
        this.worklist.forEach(item => {
          const posterGenSrc = item.posterGenSrc
          item.posterGenSrc = this.PR.previewTiny(posterGenSrc)
          item.posterGenSrcOrigin = this.PR.previewOrigin(posterGenSrc)
          item.videoSrc = this.PR.previewOrigin(item.videoSrc)
          item.modelSrc = this.PR.previewOrigin(item.modelSrc)
        })

        // TODO 这里需要loading动画
        this.worklist.push(JSON.parse(JSON.stringify(this.worklist[this.worklist.length - 1])))
        this.worklist.unshift(JSON.parse(JSON.stringify(this.worklist[0])))

        console.log('curIndex = ', this.curIndex, pageNum, this.condition.pageNum)
        if (pageNum > this.condition.pageNum) {
          this.curIndex = 1
        } else if (pageNum < this.condition.pageNum) {
          this.curIndex = this.worklist.length - 2
        }

        console.log('curIndex = ', this.curIndex)
        this.condition.pageNum = res.data.pageNum

        this.$emit('update:curWork', this.worklist[this.curIndex])
        this.$set(this.$refs.swiper.$swiper, 'activeIndex', this.curIndex)
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    judgeComponentCount() {
      if (!this.$refs.model || this.$refs.model.length === 0) {
        console.log('minus')
        this.loadCount--
      }
      if (!this.$refs.video || this.$refs.video.length === 0) {
        console.log('minus')
        this.loadCount--
      }
    },
    // 判断模型是否加载出来
    onModelLoad() {
      console.log('model loaded', this.loadCount)
      this.loadCount--
    },
    // 判断videoplayer视频是否加载完成
    onPlayerLoadeddata() {
      console.log('video loaded', this.loadCount)
      this.loadCount--
    },
    // 判断图片是否加载完成
    onPicLoad() {
      console.log('pic load', this.loadCount)
      this.loadCount--
    },
    // 向前一张
    goPrev() {
      this.loading = true
      this.loadCount = 3
      // console.log('model', this.$refs.model)
      // console.log('video', this.$refs.video)
      if (this.curIndex === 1) {
        if (this.condition.pageNum <= 1) {
          this.$message.warning('这是最开始了')
          this.loading = false
          return
        }
        setTimeout(() => {
          this.getWorkList(this.condition.pageNum - 1, this.condition.pageSize, 'prev')
        }, this.swiperOptions.speed - 100)
      }
      this.$refs.swiper.$swiper.slidePrev()
      setTimeout(() => {
        this.curIndex = this.$refs.swiper.$swiper.activeIndex
      }, 50)
    },
    // 向右一张
    goNext() {
      // console.log('go next')
      this.loading = true
      this.loadCount = 3
      // console.log('model', this.$refs.model)
      // console.log('video', this.$refs.video)
      if (this.curIndex === this.worklist.length - 2) {
        if (this.maxPage === this.condition.pageNum) {
          this.$message.warning('这是最后一个了')
          this.loading = false
          return
        }
        setTimeout(() => {
          this.getWorkList(this.condition.pageNum + 1, this.condition.pageSize, 'next')
        }, this.swiperOptions.speed - 100)
      }
      this.$refs.swiper.$swiper.slideNext()
      setTimeout(() => {
        console.log('in check active index')
        this.curIndex = this.$refs.swiper.$swiper.activeIndex
      }, 50)
    },
    // 待定作品
    toSetScore(judgeScoreId, workName) {
      this.$confirm(`确定将作品 ${workName} 设置为待定吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        judgeWork(judgeScoreId, -1, true).then(res => {
          this.$message.success(`已将作品 ${workName} 设为待定`)
          this.getWorkList(this.condition.pageNum, this.condition.pageSize)
        })
      })
    },
    // 提交作品分数
    toSubmit(judgeScoreId, score, workName) {
      const h = this.$createElement
      const msg = h('div', { style: 'font-size: 24px' },
        [h('p', { style: 'margin: 25px' }, [
          h('span', null, '作品名称： '),
          h('i', { style: 'color: teal' }, workName)
        ]), h('p', { style: 'margin: 25px' }, [
          h('span', null, '您的评分： '),
          h('i', { style: 'color: red' }, score)
        ])]
      )
      this.$confirm(msg, '评分确认', {
        customClass: 'judge-confirm',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        judgeWork(judgeScoreId, score).then(res => {
          this.$message.success(`作品 ${workName} 评分成功`)
          // this.init()
          this.getWorkList(this.condition.pageNum, this.condition.pageSize)
        })
      })
    },
    // 获得当前展示对象后缀名
    getModelSuffix() {
      const src = this.worklist[this.curIndex].modelSrc
      return src.substring(src.lastIndexOf('.') + 1).toUpperCase()
    }
  }
}
</script>

<style>
.judge-confirm {
  width: 650px;
}

/* 禁止填写时的样式 */
.el-input.is-disabled .el-input__inner {
  color: #606266;
  background-color: #fff;
}

</style>

<style scoped>
@import '~@/styles/theme.css';

.judge-confirm {
  width: 650px;
}

.fix-btn {
  position: absolute;
  line-height: 500px;
}

.left {
  left: 80px;
}

.right {
  right: 80px;
}

svg:hover {
  cursor: pointer;
}

.work-display {
  position: relative;
  width: 750px;
  height: 430px;
  margin: auto;
  margin-top: 20px;
}

.poster {
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 50%;
  left: 0px;
}

.v-and-m {
  position: absolute;
  display: inline-block;
  height: 100%;
  width: 49%;
  right: 0px;
}

.model {
  /* display: inline-block; */
  position: absolute;
  width: 100%;
  height: 49%;
  top: 0px;
}

.video {
  height: 49%;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.null {
  background-color: rgb(206, 205, 205);
  line-height: 200px;
  text-align: center;
  color: rgb(85, 85, 85);
}

.border {
  border: 1px solid red;
}

.score {
  margin-top: 30px;
  /* height: 30px; */
  text-align: center;
}

.btn {
  display: inline-block;
  width: 80px;
}

.pending.el-button--primary {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.score-input {
  display: inline-block;
  width: 130px;
  margin-left: 30px;
  margin-right: 10px;
}
</style>
